import { hasData } from "./hasData";

export const getStreamUrl = (
  stream_url_active,
  stream_url,
  listen_experience,
  player_state
) => {
  const listen_value = getM3U8(listen_experience, player_state);

  return `${stream_url_active}pl-${listen_value}.m3u8`;
};

function getM3U8(listen_experience, player_state) {
  //default state - set in user settings
  if (listen_experience?.toString() == "0") return "0";
  if (listen_experience?.toString() == "1") return "1";
  if (listen_experience?.toString() == "2") return "2";

  //catch all
  return "0";
}

export function getUserListenExperience(userdata) {
  return hasData(userdata?.listen_experience)
    ? userdata?.listen_experience //custom for member
    : !hasData(userdata?.id) || userdata?.id == 0
    ? 0 //lofi for guests
    : 1; //medium for member as a default
}
