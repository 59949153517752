import { createContext, useState } from "react";

export const ModalContext = createContext(null);

function ModalContextProviderNested({ children, pageProps }) {
  const [isOpen, set_isOpen] = useState("");

  return (
    <ModalContext.Provider value={{ isOpen, set_isOpen }}>
      {children}
    </ModalContext.Provider>
  );
}

export const ModalContextProvider = ModalContextProviderNested;
