import { createContext, useEffect, useRef, useState } from "react";
import { AudioFile } from "../player/audio-file"; //\ './audio-file';
import { AudioLive } from "../player/audio-live";
import NoSleep from "nosleep.js";
import { hasData } from "@/lib/utils/hasData";
import { useUserAuth } from "@/lib/utils/hooks/useUserAuth";
import { getUserListenExperience } from "@/lib/utils/getStreamUrl";

export const PlayerContext = createContext(null);

function PlayerContextProviderNested({ children, pageProps }) {
  const playerRef = useRef(null);
  var volume = 0.8;
  var volume_slider = 80;

  //localstorage override
  if (
    typeof window !== "undefined" &&
    hasData(localStorage.getItem("volume_slider")) &&
    Number.isInteger(parseInt(localStorage.getItem("volume_slider")))
  ) {
    volume = parseInt(localStorage.getItem("volume_slider")) / 100;
    volume_slider = parseInt(localStorage.getItem("volume_slider"));
  }

  // const player_state_localstorage = localStorage.getItem('player_state');
  // var state_defaults = player_state_defaults(playerRef)
  // if (hasData(player_state_localstorage)) var player_state_localstorage_obj = JSON.parse(player_state_localstorage)
  // state_defaults = {...state_defaults,
  //   file:player_state_localstorage_obj.file
  // }

  const { userdata, isUserDataLoading, isUserDataError } = useUserAuth();

  const player_state_defaults = {
    file: "",
    url: "",
    title: "",
    seek: 0,
    seek_ratio: 0,
    seek_slider: 0,
    length: 0,
    user_id: 0,
    user_name: "",
    post_id: 0,
    post_type: 5, //showreel
    content_id: 0,
    content_id_last: 0,
    content_table: "",
    avatar_url: "",
    playerRef: playerRef,
    isPlaying: false,
    isLoading: true,
    isMuted: false,
    isMediaLoaded: false,
    isBuffering: true,
    isSeeking: false,
    isEnded: false,
    volume: volume,
    volume_slider: volume_slider,
    isReady: false,
    player_type: "file", //live
    stream_status: "0",
    duration: 0,
    stream_start_date: "",
    stream_end_date: "",
    server_timestamp: "",
    useHLS: false,
    listener: null,
    listener_stored_id: null,
    listener_stored_broadcast: null,
    listener_ip: null,
    isBrowserDetected: false,
    broadcast_status: 0,
    current_listeners: 0,
    current_chatters: 0,
    sum_listeners: 0,
    sum_members: 0,
    sum_chatters: 0,
    sum_emojis: 0,
    sum_chats: 0,
    broadcast_id: 0,
    start_date: "",
    broadcast_title: "",
    broadcast_description: "",
    contributor_level: 0,
    bol_flair: 0,
    flair_json: [],
    flair_tag_json: [],
    isHide: "1",
    playbackRate: 1,
    listen_experience: getUserListenExperience(userdata),
    bol_video: 0,
    bol_dedicated: 0,
    media_type: "audio",
  };

  const [player_state, set_player_state] = useState(player_state_defaults);

  //no sleep
  useEffect(() => {
    var noSleep = new NoSleep();

    try {
      if (!player_state?.isPlaying) {
        //   noSleep?.enable();
        // } else {
        noSleep?.disable();
      }
    } catch (error) {
      //console.log("error in NoSleep function",error)
    }

    return () => {
      noSleep?.disable();
    };
  }, [player_state?.isPlaying]);

  return (
    <PlayerContext.Provider value={{ player_state, set_player_state }}>
      <div className={`hidden`}>
        {player_state?.player_type == "live" && (
          <AudioLive
            stream_url={player_state?.file}
            content_id={player_state?.content_id}
            content_table={player_state?.content_table}
            userdata={userdata}
          />
        )}

        {player_state?.player_type == "file" && (
          <AudioFile
            stream_url={player_state?.file}
            content_id={player_state?.content_id}
            content_table={player_state?.content_table}
            host={player_state?.user_id}
            playerRef={player_state.playerRef}
            post_id={player_state?.post_id}
          />
        )}
      </div>
      {children}
    </PlayerContext.Provider>
  );
}

export const PlayerContextProvider = PlayerContextProviderNested; //memo(PlayerContextProviderNested)
