import { hasData } from "@/lib/utils/hasData";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

type tribeObj = {
  isLoading: boolean;
  [key: string]: any;
};

const tribe: tribeObj = { isLoading: false };

export const TribeContext = createContext(tribe);

export const TribeContextProvider = ({ ssr_data_tribe, children }) => {
  const [tribeContextValue, set_tribeContextValue] = useState(null);

  useEffect(() => {
    if (!hasData(tribeContextValue?.tribe_id)) {
      //already have data from serverside props in App.ts
      if (
        hasData(ssr_data_tribe?.tribe_id) &&
        ssr_data_tribe?.tribe_id?.toString() !== "0"
      ) {
        set_tribeContextValue({
          ...ssr_data_tribe,
          isLoading: false,
          set_tribeContextValue,
        });
      } else {
        //data doesnt exist so pull from db as last resort

        axios("/api/private/tribe/select-context")
          .then(({ data }) => {
            if (data) {
              set_tribeContextValue({
                ...data,
                isLoading: false,
                set_tribeContextValue,
              });
            } else {
              set_tribeContextValue({
                tribe_id: 0, //Math.floor(100000 + Math.random() * 900000)*-1
                brand_name: "Failed",
                isLoading: false,
              }); // not authenticated
            }
          })
          .catch((err) => {
            console.log("TribeContext could not fetch tribe", err);
          });
      }
    }

    return () => {
      //console.log('TribeContextProvider cleanup');
    };
  }, []);

  return (
    <TribeContext.Provider value={tribeContextValue}>
      {children}
    </TribeContext.Provider>
  );
};

export const useTribeContext = () => useContext(TribeContext);
