import useSWR from "swr";

const fetcher = async (url) => {
  const res = await fetch(url);
  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    // Attach extra info to the error object.
    //@ts-ignore
    error.info = await res.json();
    //@ts-ignore
    error.status = res.status;
    throw error;
  }
  return res.json();
};

// REFACTORED SWR CALLS
// ---------------------------------------------------------
// SWR NEW - USE THIS MOVING FORWARD AND REFACTOR ALL OTHERS
// ---------------------------------------------------------

export function useSWRWrapper(
  id?: string,
  key?: string,
  retry?: boolean,
  retry_count?: number,
  retryInterval?: number,
  refreshInterval?: number
) {
  // console.log("refreshInterval",refreshInterval,key)

  const swroptions = {
    refreshInterval: refreshInterval,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      // Never retry on 404.
      if (error.status === 404) return;

      // Never retry for a specific key.
      //if (key === '/api/user') return

      //only retry if specifically requested
      if (!retry) return;

      // Only retry up to 5 times.
      if (retryCount >= retry_count) return;

      // Retry after 5 seconds.
      setTimeout(() => revalidate({ retryCount }), retryInterval);
    },
  };

  const { data, error, mutate } = useSWR(id ? key : null, fetcher, swroptions);

  if (error) {
    //  console.log("SWR error",key, id, error.status,error.info,error)
  }

  return {
    data: data,
    loading: !error && !data,
    error: error,
    boundMutate: mutate, // see about bound mutate here: https://github.com/vercel/swr#bound-mutate
  };
}
