import React from "react";

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
    // let report =
    // `Error:
    // ================================================

    // ${JSON.stringify(error)}

    // ================================================

    // ${JSON.stringify(errorInfo)}

    // ================================================`

    //    try {

    //        await getPrepEmail({
    //              emailto: ssr_data_tribe.email_support
    //            ,emailtoname: "SUPPORT"
    //            ,bol_notification_bitarray: "1"
    //            ,notification_bitarray: 32767
    //            ,notification_type:'change'
    //            ,emailfrom: ssr_data_tribe.email_notifications
    //            ,emailfromname: ssr_data_tribe?.brand_name
    //            ,emailsubject: `App Error Auto-report`
    //            ,emailbodytext: report
    //            ,emailbodyhtml: report //optional
    //            ,emailccto: ssr_data_tribe.email_notifications //include support to reply
    //            ,emailcctoname: ssr_data_tribe?.brand_name //include support to reply
    //          })

    //    } catch (e) {
    //      //set_submitting(false)
    //      throw Error(e.message)
    //    }
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is a rendering error! Contact support or </h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
            className="border px-2 p-1 rounded-md mt-2 text-white"
          >
            refresh to try again?
          </button>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
