import React from "react";
import AuthContext from "@/components/auth/context";
import { hasData } from "../hasData";

export function useUserAuth(userdata = AuthContext) {
  const authContext = React.useContext(userdata);
  var authData;
  var authError = false;
  var authLoading = true;

  //See if we finished loading the component
  if (
    authContext !== null &&
    (!hasData(authContext?.isLoading) || !authContext?.isLoading)
  ) {
    authLoading = false;
  }

  //console.log("authLoading",authLoading,authContext)

  //See if we have the data
  if (!authLoading && (authContext !== null || authContext !== undefined)) {
    authData = authContext;
  }

  //otherwise its an error...
  if (
    !authLoading &&
    !authData &&
    (authContext !== null || authContext !== undefined)
  ) {
    authError = true;
  }

  //console.log("useUserAuth returning")

  return {
    userdata: authData,
    isUserDataLoading: authLoading,
    isUserDataError: authError,
  };
}
