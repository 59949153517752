import "../styles/index.css";
import Auth from "@/components/auth/auth";
import { Provider as NextAuthContextProvider } from "next-auth/client";
import Script from "next/script";
import { PlayerContextProvider } from "@/components/context/player-context";
import ErrorBoundary from "@/components/templateux/error-boundary";
import { PushContextProvider } from "@/components/context/push-context";
import { Analytics } from "@vercel/analytics/react";
import ConfirmContextProvider from "@/components/context/confirm-context";
import { ModalContextProvider } from "@/components/context/modal-context";
import { TribeContextProvider } from "@/components/context/tribe-context";
import { SpeedInsights } from "@vercel/speed-insights/next";
import PromptContextProvider from "@/components/context/prompt-context";
import BrushContextProvider from "@/components/context/brush-context";

export async function getServerSideProps(context) {
  return {
    pageProps: {},
  };
}

function App({ Component, pageProps }) {
  return (
    <TribeContextProvider ssr_data_tribe={pageProps.ssr_data_tribe}>
      <PushContextProvider>
        <NextAuthContextProvider session={pageProps.session}>
          <Auth>
            <ConfirmContextProvider>
              <PromptContextProvider {...pageProps}>
                <ModalContextProvider {...pageProps}>
                  <PlayerContextProvider {...pageProps}>
                    <BrushContextProvider>
                      <ErrorBoundary>
                        <Component {...pageProps} />
                        <Analytics />
                        <SpeedInsights />
                      </ErrorBoundary>
                    </BrushContextProvider>
                  </PlayerContextProvider>
                </ModalContextProvider>
                <Script
                  src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICSID}`}
                  strategy="afterInteractive"
                />
                <Script id="google-analytics" strategy="afterInteractive">
                  {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICSID}');
                  `}
                </Script>
              </PromptContextProvider>
            </ConfirmContextProvider>
          </Auth>
        </NextAuthContextProvider>
      </PushContextProvider>
    </TribeContextProvider>
  );
}

export default App;
