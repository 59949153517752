import { hasData } from "@/lib/utils/hasData";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player/file";
import Slider from "react-input-slider";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { config } from "@/lib/config";
import { PlayerContext } from "../context/player-context";
import { getTimestamp } from "@/lib/utils/getTimestamp";
import { getPlayUrl } from "@/lib/utils/getPlayUrl";

export function AudioFileNested({
  stream_url,
  content_id,
  content_table,
  host,
  playerRef,
  post_id,
}) {
  // console.log("content_table",content_table,content_id)

  const { player_state, set_player_state } = useContext(PlayerContext);

  const [isMounted, set_isMounted] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    set_isMounted(true);
    return () => {};
  }, []);

  const [stream_post_id, set_stream_post_id] = useState(post_id);

  // console.log("stream_post_id",getPlayUrl(stream_post_id),stream_url);

  useEffect(() => {
    if (stream_post_id !== player_state?.post_id)
      set_stream_post_id(player_state?.post_id);
    //if (stream_post_id !== player_state?.file) set_stream_post_id(player_state?.file)
  }, [player_state?.post_id, stream_post_id]);

  useEffect(() => {
    if (hasData(player_state?.start_seek) && player_state?.start_seek > 0) {
      let timestamp = player_state?.start_seek;

      player_state?.playerRef?.current?.seekTo(timestamp, "seconds");
      set_player_state({
        ...player_state,
        seek: timestamp,
        seek_ratio:
          getTimestamp(timestamp) !== 0
            ? (timestamp * 100) / player_state?.duration
            : 0,
        seek_slider:
          getTimestamp(timestamp) !== 0
            ? (timestamp * 100) / player_state?.duration
            : 0,
        isEnded: timestamp >= player_state.duration,
      });
    }
  }, [player_state?.start_seek]);

  //LISTENER TRACKING

  //type 1 = start
  //type 2 = ping
  //type 0 = end
  useSWRWrapper(
    player_state?.listener, //id
    `/api/public/v0/broadcast/listen?id=${content_id}&table=${content_table}&host=${host}&type=2&origin=local&listener=${player_state?.listener}`, //key
    true, //retry?
    config.max_retries, //max number of retries
    config.retry_interval, //retry interval
    config.profile.listen_interval //refreshinterval (0=none)
  );

  //track start playing at stream start
  const listenStart = async (content_id, content_table) => {
    //console.log("Listen Start")

    //playerRef.seekTo(seek,'seconds');
    // await set_listener(null);
    await set_player_state({
      ...player_state,
      listener: null, //reset state
    });

    //SEE IF WE ALREADY HAVE THE LISTENER ID IN LISTENER_STORED
    if (hasData(player_state?.listener_stored_id)) {
      //assign it to ACTIVE if the broadcast hasn't changed
      if (player_state?.listener_stored_broadcast == content_id) {
        await set_player_state({
          ...player_state,
          listener: player_state?.listener_stored_id,
          listener_stored_id: player_state?.broadcast_listener_id,
        });
        // await set_listener(listener_stored_id);
      }
    }

    //OTHERWISE PULL IT FROM THE DB IF WE CAN
    if (!hasData(player_state?.listener_stored_id) && hasData(content_id)) {
      const fetch_str = `/api/public/v0/broadcast/listen?id=${content_id}&table=${content_table}&host=${host}&type=${
        !hasData(player_state?.listener) ? "1" : "2"
      }&origin=local&listener=${player_state?.listener}`;
      //console.log("fetch_str",fetch_str)
      const res = await fetch(fetch_str);
      const json = await res.json();
      if (json) {
        let { outcome, broadcast_listener_id } = json;

        //SAVE IT TO MEMORY AND SET IT ACTIVE, SO SWR STARTS TRIGGERING REFRESHES

        set_player_state({
          ...player_state,
          listener: broadcast_listener_id,
          listener_stored_id: broadcast_listener_id,
        });

        // console.log("player_state2",player_state)

        //console.log('CREATING NEW LISTENER ACTIVE',broadcast_listener_id,json)
      }
    }
  };

  //run one last data capture and clear SWR refreshs after stream end
  const listenEnd = async (content_id, content_table) => {
    //console.log("listen End")

    await fetch(
      `/api/public/v0/broadcast/listen?id=${content_id}&table=${content_table}&host=${host}&type=0&origin=local&listener=${player_state?.listener}`
    );
    // set_listener(null);
  };

  const onReady = useCallback(() => {
    if (!isReady) {
      const timeToStart = player_state?.start_seek || 0;
      playerRef?.current?.seekTo(timeToStart, "seconds");
      setIsReady(true);
    }
  }, [isReady]);

  const onProgress = (state) => {
    //console.log("state",state)

    if (player_state?.isEnded) return;

    //We only want to update time slider if we are not currently seeking

    //console.log("state",player_state.isSeeking)
    if (!player_state.isSeeking) {
      set_player_state({
        ...player_state,
        seek: state.playedSeconds,
        duration: playerRef.current.getDuration(),
        seek_ratio: state.played,
        seek_slider: state.played * 100,
        isLoading: false,
        isLoaded: true,
      });
    } else {
      const seekto = player_state.seek;
      if (!isFinite(seekto)) {
        //console.log("seekto",player_state.seek)
        playerRef.current.seekTo(seekto, "seconds");
        set_player_state({ ...player_state, isSeeking: false });
      }
    }
  };

  if (!hasData(getPlayUrl(stream_post_id)))
    return (
      <>
        <div className="text-center text-red-500 font-bold w-full ">
          No stream URL! Refresh to fix this or contact support if the error
          persists. Error code [{player_state?.content_table}_
          {player_state?.content_id}]
        </div>
      </>
    );

  // console.log("ReactPlayer.canPlay(getPlayUrl(stream_post_id))",ReactPlayer.canPlay(getPlayUrl(stream_post_id)))

  return (
    <>
      {/* {ReactPlayer.canPlay(getPlayUrl(stream_post_id)) &&    */}
      <div className="w-full ">
        <div className="">
          {/* STATUS */}
          {player_state?.content_table}: {player_state?.content_id}
          File: {getPlayUrl(stream_post_id)}
          Loaded: {player_state?.isMediaLoaded ? "true" : "false"} | Buffering:{" "}
          {player_state?.isBuffering ? "true" : "false"} |{/* TOGGLE */}
          <button
            onClick={() => {
              //set_isPlaying(!isPlaying)
              set_player_state({
                ...player_state,
                isPlaying: !player_state.isPlaying,
              });
            }}
          >
            {/* {isPlaying ? "Pause" : "Play"} */}

            {player_state.isPlaying ? "Pause" : "Play"}
          </button>{" "}
          |{/* MUTE */}
          <button
            onClick={() => {
              // set_isMuted(!isMuted)
              set_player_state({
                ...player_state,
                isMuted: !player_state?.isMuted,
              });
            }}
          >
            {player_state?.isMuted ? "Unmute" : "Mute"}
          </button>{" "}
          |{/* VOLUME */}
          {player_state?.volume} |
          <Slider
            axis="y"
            y={player_state?.volume_slider}
            ymax={100}
            onChange={({ y }) => {
              set_player_state({
                ...player_state,
                volume: (100 - y) / 100,
                volume_slider: y,
              });
              // set_volume((100-y)/100)
              // set_volume_slider((y))
              localStorage.setItem("volume_slider", y?.toString());
            }}
            styles={{
              active: {
                backgroundColor: "#000000",
              },
              track: {
                backgroundColor: player_state?.isMuted ? "#555555" : "#111827",
                display: "flex",
                height: 40,
              },
              thumb: {
                width: 15,
                height: 15,
                backgroundColor: player_state?.isMuted ? "#c0c0c0" : "#ffffff",
                border: "1px solid #a0a0a0",
              },
              disabled: {
                opacity: 0.5,
              },
            }}
          />
        </div>
        {!player_state?.isMediaLoaded && (
          <div
            className={`w-full text-center h-10 flex items-center content-center`}
          >
            <div className="flex-1 text-center animate-pulse">
              <div className="font-bold">Loading stream...</div>
              <div className="italic text-xs">
                Refresh if this takes longer than 10 seconds.
              </div>
              hls: {player_state?.useHLS ? "true" : "false"}
            </div>
          </div>
        )}

        <div
          className={`w-full  h-10 ${
            player_state?.isMediaLoaded ? " block " : "  block "
          }`}
        >
          <ReactPlayer
            ref={player_state?.playerRef}
            url={getPlayUrl(stream_post_id)}
            config={{
              forceAudio: true,
            }}
            width="100%"
            height="40px"
            autoPlay={player_state?.isMediaLoaded}
            preload={"auto"}
            controls={false}
            playing={player_state.isPlaying}
            muted={player_state?.isMuted}
            volume={player_state?.volume}
            playbackRate={player_state?.playbackRate}
            onReady={onReady}
            // onReady={player_state?.player_type == "live"
            //             ? onReady
            //             : ()=>{}
            //         }
            onProgress={onProgress}
            // onStart={()=> {
            //     listenStart(content_id);
            //     console.log("audio started");
            //     set_isPlaying(true);
            // }}
            onPlay={() => {
              //console.log("onPlay triggered")

              listenStart(content_id, content_table);
              //console.log("audio started");
              set_player_state({
                ...player_state,
                isPlaying: true,
                isSeeking: false,
                isLoading: false,
                isMediaLoaded: true,
              });
            }}
            onPause={() => {
              listenEnd(content_id, content_table);
              //console.log("audio paused");

              set_player_state({
                ...player_state,
                isPlaying: false,
                isSeeking: false,
                listener: null,
              });
            }}
            onEnded={() => {
              listenEnd(content_id, content_table);
              // console.log("audio ended");

              set_player_state({
                ...player_state,
                isPlaying: false,
                isSeeking: false,
                isEnded: true,
                listener: null,
                seek: player_state.duration,
                seek_ratio: 1,
                seek_slider: 100,
              });
            }}
            // onSeek={()=> {
            //     set_player_state({...player_state,isSeeking:true})
            // }}
            // onError={()=> alert("error")}
            onBuffer={() =>
              set_player_state({ ...player_state, isBuffering: true })
            }
            onBufferEnd={() =>
              set_player_state({ ...player_state, isBuffering: false })
            }
          />
        </div>
      </div>

      {/* } */}
    </>
  );
}

export const AudioFile = memo(AudioFileNested);
