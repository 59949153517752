import { config } from "@/lib/config";
import React, { createContext, useState } from "react";

export const BrushContext = createContext(null);

const BrushContextProvider = ({ children }) => {
  const [brush, set_brush] = useState({
    color: config.draw.brushcolor,
    alpha: config.draw.brushalpha,
    radius: config.draw.brushradius,
    cursor: false,
  });

  return (
    <BrushContext.Provider value={[brush, set_brush]}>
      {children}
    </BrushContext.Provider>
  );
};

export default BrushContextProvider;
