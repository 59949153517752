import React, { createContext, useState } from "react";

export const PromptContext = createContext(null);

const PromptContextProvider = ({ children, pageProps }) => {
  const [prompt, setPrompt] = useState({
    prompt: <></>,
    value: null,
    set_value: () => {},
    isPromptOpen: false,
    proceed: null,
    cancel: null,
    onSubmit: () => null,
  });

  return (
    <PromptContext.Provider value={[prompt, setPrompt]}>
      {children}
    </PromptContext.Provider>
  );
};
export default PromptContextProvider;
