import React, { useEffect } from "react";
import Context from "@/components/auth/context";
import { useSession } from "next-auth/client";
import Cookies from "js-cookie";
import axios from "axios";
import { UserContextValue } from "./types";
import { hasData } from "@/lib/utils/hasData";

type Props = {
  children: React.ReactNode;
};

function Auth({ children }: Props) {
  const [userContextValue, set_userContextValue] =
    React.useState<UserContextValue>({ isLoading: true });
  const [session, isLoading] = useSession();

  /**
   * This route requires authentication and the user is not authenticated. We are already
   * rendering the unauthorized component below, but lets update the URL without reloading the page
   */
  // useEffect(() => {
  //   if (isRouteRequiresAuth(router.pathname) && userContextValue === null) {
  //     router.replace(window.location.pathname, '/unauthorized', { shallow: true });
  //   }
  // }, [userContextValue]);

  useEffect(() => {
    const { usertoken } = Cookies.get();
    const { user, sessionToken } = Cookies.get();

    if (!isLoading) {
      if (session) {
        if (!hasData(userContextValue?.id)) {
          //data doesnt exist so pull from db as last resort
          axios("/api/private/user/select-prefs")
            .then(({ data }) => {
              if (data) {
                set_userContextValue({
                  ...data,
                  isLoading: false,
                  set_userContextValue,
                });
              } else {
                set_userContextValue({
                  id: 0, //Math.floor(100000 + Math.random() * 900000)*-1
                  user_name: "Guest",
                  isLoading: false,
                }); // not authenticated
              }
            })
            .catch((err) => {
              console.log("<Auth /> could not fetch user");
            });
        }
      } else {
        set_userContextValue({
          id: 0, //Math.floor(100000 + Math.random() * 900000)*-1
          user_name: "Guest",
          isLoading: false,
        }); // not authenticated
      }
    }
  }, [session, isLoading, userContextValue?.id]);

  // This route requires authentication and we aren't sure yet if the user is authenticated
  // if (isRouteRequiresAuth(router.pathname) && userContextValue === undefined) {
  //   return <Loading />
  // }

  // This route requires authentication and the user is not authenticated
  // if (isRouteRequiresAuth(router.pathname) && userContextValue === null) {
  //   return <Unauthorized />
  // }

  return (
    <Context.Provider value={userContextValue}>{children}</Context.Provider>
  );
}

export default Auth;
