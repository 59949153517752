import React, { createContext, useState } from "react";
import ModalConfirm from "../templateux/modal/modal-confirm";

export const ConfirmContext = createContext(null);

const ConfirmContextProvider = ({ children }) => {
  const [confirm, setConfirm] = useState({
    prompt: <></>,
    isConfirmOpen: false,
    proceed: null,
    cancel: null,
  });

  return (
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      {children}
      <ModalConfirm />
    </ConfirmContext.Provider>
  );
};
export default ConfirmContextProvider;
